import { Inject, NgModule, Optional, PLATFORM_ID, TransferState, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guard/auth.guard';

import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './components/home/home.component';
import { CartComponent } from './components/cart/cart.component';
import { QuickOrderComponent } from './components/quick-order/quick-order.component';
import { Error404Component } from './components/page/error404/error404.component';
import { BlogComponent } from './components/blog/blog.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { AccountGuard } from './core/guard/account.guard';
import { IngredientComponent } from './components/ingredient/ingredient.component';
import { BlogService } from './shared/services/blog.service';
import { HomeConfigurationService } from './shared/services/homeconfiguration.service';
import { AuthService, COOKIES, DEFAULT_VERSION, IS_ACCOUNT_ACTIVE, IS_AUTHENTICATED, REFRESH_TOKEN, TOKEN, CURRENT_VERSION, LATEST_VERSION, USERNAME } from './shared/services/auth.service';
import { isPlatformServer } from '@angular/common';
import { REQUEST } from '@/express.tokens';
import { CommonService } from './shared/services/common.service';
import { jwtDecode } from 'jwt-decode';
import { WheelComponent } from './components/wheel/wheel.component';
import { NotificationComponent } from './components/notification/notification.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'error404',
    component: Error404Component
  },
  {
    path: '',
    component: LayoutComponent,
    resolve: {
      homeConfig: () => inject(HomeConfigurationService).getHomeConfigInfo()
    },
    children: [
      {
        path: '',
        component: HomeComponent,
        // resolve: {
        //   homeBanner: () => inject(HomeConfigurationService).getHomeBannerInfo(),
        //   venderBanner: () => inject(HomeConfigurationService).getVendorBannerInfo(),
        //   commentBanner: () => inject(HomeConfigurationService).getCommentBannerInfo(),
        //   flashSale: () => inject(FlashSaleService).getFlashSale(),
        // }
      },
      {
        path: 'product',
        loadChildren: () => import("@/app/components/product/product.module").then(m => m.ProductModule)
        // component: ProductComponent
      },
      {
        path: 'san-pham',
        loadChildren: () => import("@/app/components/product/product.module").then(m => m.ProductModule)
        // component: ProductComponent
      },
      // {
      //   path: 'product/:id',
      //   component: ProductDetailComponent,
      //   resolve: {
      //     product: (route: ActivatedRouteSnapshot) => inject(ProductService).getProductBySlug(route.params['id']).pipe(
      //       catchError(() => of(null))
      //     )
      //   }
      // },
      {
        path: 'ingredient',
        component: IngredientComponent
      },

      {
        path: 'cart',
        component: CartComponent
      },
      {
        path: 'quick-order',
        component: QuickOrderComponent,
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'flash-sale',
      //   component: FlashSaleComponent,
      //   canActivate: [AuthGuard]
      // },
      {
        path: "auth",
        loadChildren: () => import("@/app/components/auth/auth.module").then(m => m.AuthModule)
      },
      {
        path: "account",
        loadChildren: () => import("@/app/components/account/account.module").then(m => m.AccountModule),
        canActivate: [AccountGuard],
        canActivateChild: [AccountGuard]
      },
      {
        path: "blog",
        loadChildren: () => import("@/app/components/blog/blog.module").then(m => m.BlogModule)
        // component: BlogListComponent,
      },
      {
        path: 'thank-you',
        component: ThankYouComponent
      },
      {
        path: 'wheel',
        component: WheelComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'thong-bao',
        component: NotificationComponent,
      },
      {
        path: ':slug',
        component: BlogComponent,
        resolve: {
          blog: (route: ActivatedRouteSnapshot) => inject(BlogService).getBlogBySlug(route.params['slug'])
        }
      },
    ],
  },
  {
    path: '**',
    redirectTo: "/error404",
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: any,
    private transferState: TransferState,
    private commonService: CommonService,
    private authService: AuthService,
    private router: Router
  ) {
    if (isPlatformServer(this.platformId)) {
      this.request.cookies = this.request?.headers?.cookie;
      this.transferState.set(COOKIES, this.request?.cookies);

      const isAuthenticated = this.commonService.getCookie(this.request.cookies, 'token') ? true : false;
      this.transferState.set(IS_AUTHENTICATED, isAuthenticated);

      const token = this.commonService.getCookie(this.request.cookies, 'token');
      this.transferState.set(TOKEN, token);

      const refreshToken = this.commonService.getCookie(this.request.cookies, 'refreshToken');
      this.transferState.set(REFRESH_TOKEN, refreshToken);

      const currentVersion = this.commonService.getCookie(this.request.cookies, 'currentVersion');
      this.transferState.set(CURRENT_VERSION, currentVersion);

      const username = this.commonService.getCookie(this.request.cookies, 'username');
      this.transferState.set(USERNAME, username);

      if (isAuthenticated) {
        const jwt = jwtDecode(token) as any;
        const isAccountActivated = jwt.Status === 'Active';
        this.transferState.set(IS_ACCOUNT_ACTIVE, isAccountActivated);
      }

      const latestVersion = '1.9.0';

      // this.authService.getLatestVersion().subscribe({
      //   next: (latestVersion: any) => {
      this.transferState.set(LATEST_VERSION, latestVersion);

      if (currentVersion !== latestVersion) {
        // this.authService.logout();
        // this.router.navigate(['/auth/login']);
        
        this.transferState.set(CURRENT_VERSION, latestVersion);
        // console.log('Version not up to date');
      }
      //   },
      //   error: (error) => {
      //     console.log(error);
      //     this.authService.setCookie('currentVersion', DEFAULT_VERSION);
      //     this.transferState.set(CURRENT_VERSION, DEFAULT_VERSION);
      //   }
      // });
    }
    else {
      const currentVersion = this.transferState.get(CURRENT_VERSION, DEFAULT_VERSION);
      const latestVersion = this.transferState.get(LATEST_VERSION, DEFAULT_VERSION);
      if (currentVersion != latestVersion) {
        this.transferState.set(CURRENT_VERSION, latestVersion);
        //window.location.reload();
      }
      this.authService.setCookie('currentVersion', latestVersion);
    }
  }
}
