
<div class="onhover-noti-dropdown">
  <!--  white border for this div-->
  <div class="d-inline-block">
    <div class="d-inline-block" style="margin-right: -5px" (click)="goToNotiPage()">
      <a href="javascript:void(0)" class="header-icon search-icon border-0" *ngIf="style == 'standard'">
        <i class="ri-notification-line text-white fs-3" #notificationIcon> </i>
        <small class="badge-number text-bg-warning">{{notificationCount}}</small>
      </a>
    </div>
  </div>

</div>
<div class="notification-dropdown" *ngIf="isShowNoti" #notificationDropdown>
  <div class="header">
    <h3>Thông báo</h3>
  </div>
  <div class="tabs">
    <div class="tab" [class.active]="selectedTab === 1" (click)="selectTab(1)">Sản phẩm</div>
    <div class="tab" [class.active]="selectedTab === 2" (click)="selectTab(2)">Khuyến mại</div>
  </div>

  <div class="tab-content">
    <div *ngIf="selectedTab === 1" style="padding: unset;">
      <div class="noti-el" style="margin-bottom: 10px;" *ngFor="let product of notiProducts; let i = index">
        <div>
          <span class="product-price">Đơn giá</span>
          <span>{{product.time}}</span>
          <span style="color: #0C9347FF">({{product.date}})</span>
        </div>
        <div class="noti-content" style="margin-top: 10px;">
          <a class="text-dark" [routerLink]="product.url">{{product.content}}</a>
        </div>
      </div>

    </div>
    <div *ngIf="selectedTab === 2" style="padding: unset;">
      <div class="noti-el" style="margin-bottom: 10px;" *ngFor="let promotion of promotionNoti; let i = index">
        <div>
          <span class="promotion">Khuyến mãi</span>
          <span>{{promotion.time}}</span>
        </div>
        <div class="noti-content" style="margin-top: 10px">
          <a class="text-dark" [routerLink]="promotion.url">{{promotion.content}}</a>
        </div>
      </div>
    </div>
  </div>
</div>