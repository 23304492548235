import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { Province } from "../interface/province.interface";

@Injectable({
  providedIn: "root",
})
export class ProvinceService {

  constructor(private http: HttpClient) {}

  // getProvinceInfo() {
  //   return this.http.get<Province[]>(`${environment.baseURL}provinces`);
  // }

  getProvinceInfoByDepth(provincecode: number, districtcode: number){
    return this.http.get<Province[]>(`${environment.baseURL}user/province/province-depth?provincecode=${provincecode}&districtcode=${districtcode}`);
  }

  getProvinceInfoByRegion(){
    return this.http.get<Province[]>(`${environment.baseURL}user/province/province-region`);
  }

}
