import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { PagingResponseModel } from "../interface/pagination.interface";
import { ProductModel, RetriveUserModel } from "../interface/product.interface";
import { Tag } from "../interface/tag.interface";
import { PointUsage } from "../interface/user.interface";

@Injectable({
  providedIn: "root",
})
export class GiftService {

    private giftInfo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public getGiftInfo: Observable<any> = this.giftInfo.asObservable();
    public setGiftInfo(data: any) {
        this.giftInfo.next(data);
    }

    constructor(
        private http: HttpClient
    ) {}

    getGiftList(body: string) {
        return this.http.post<ProductModel>(`${environment.baseURL}product/product/retrieve-products-gift`, body, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }

    getGiftHistoryList(body: string) {
        return this.http.post(`${environment.baseURL}user/user/point`, body, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }

    getGiftInfomation() {
        return this.http.get(`${environment.baseURL}configuration/configuration/gift-info`);
    }

    getTotalGiftPoint() {
        return this.http.get(`${environment.baseURL}user/user/point-user`);
    }

}
