import { Breadcrumb } from '@/app/shared/interface/breadcrumb';
import { NotificationDto, NotificationRequestModel } from '@/app/shared/interface/notification.interface';
import { PagingResponseModel } from '@/app/shared/interface/pagination.interface';
import { PaginationModel } from '@/app/shared/interface/product.interface';
import { CommonService } from '@/app/shared/services/common.service';
import { PushNotificationService } from '@/app/shared/services/push-notification.service';
import { QueryStringService } from '@/app/shared/services/querystring.service';
import { formatDate, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {
  public breadcrumb: Breadcrumb = {
    title: "Thông báo",
    items: [{ label: 'Thông báo', active: false }]
  };

  public data: NotificationDto[] = [];
  private formatDateValue = 'dd/MM/yyyy HH:mm:ss';
  private changeDataSubcription?: Subscription;
  private subcribe?: Subscription;
  itemsPerPage = 50;

  constructor(private pushNotiService: PushNotificationService,
    private queryStringService: QueryStringService,
    private commonService: CommonService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.changeDataSubcription = this.queryStringService.eventChangeQueryString.subscribe(() => {
        this.getData();
      });

      this.subcribe = this.commonService.notificationChange$.subscribe({
        next: () => {
          this.getData();
        }
      })

      this.queryStringService.startFetchingData();
    }
  }

  getData() {
    let body: NotificationRequestModel = this.queryStringService.buildFetchingModel(this.itemsPerPage);
    this.pushNotiService.getNotifications(body).subscribe({
      next: (res: PagingResponseModel<NotificationDto>) => {
        if (res.data) {
          this.data = res.data;
          let pagination: PaginationModel = {
            limit: body.limit,
            offset: body.offset,
            searchTerm: body.searchTerm,
            totalItems: res.total,
            sortBy: body.sortBy,
            category: "",
          };
          this.queryStringService.changePagination(pagination);
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
      }
    });
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  dateTimeFormat(date: string) {
    if (date) {
      return formatDate(date, this.formatDateValue, 'vi-VN');
    }
    else {
      return "-";
    }
  }
  viewNotificationDetail(noti: NotificationDto) {
    if (!noti.isRead) {
      this.pushNotiService.readNotification(noti.id).subscribe({
        next: (res: boolean) => {
          this.commonService.notificationChange();
        }
      });
    }
    let url = NotificationDto.getNotiTargetUrl(noti);
    if (url) {
      this.router.navigateByUrl(url);
    }
  }
  ngOnDestroy() {
    this.changeDataSubcription?.unsubscribe();
    this.subcribe?.unsubscribe();
  }
}
