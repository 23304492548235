import { PostCategory, PostModel, PostPagingModel } from '@/app/shared/interface/blog.interface';
import { Breadcrumb } from '@/app/shared/interface/breadcrumb';
import { PaginationModel, RetriveUserModel } from '@/app/shared/interface/product.interface';
import { BlogService } from '@/app/shared/services/blog.service';
import { QueryStringService } from '@/app/shared/services/querystring.service';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrl: './blog-list.component.scss'
})
export class BlogListComponent {
  public breadcrumb: Breadcrumb = {
    title: "Blog",
    items: [{ label: 'Blog', active: false }]
  };

  public category_sidebar: PostCategory[] = [];
  posts: PostModel[] = [];
  private changeDataSubcription?: Subscription;

  itemsPerPage = 10;

  constructor(
    private blogService: BlogService,
    private queryStringService: QueryStringService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.getPinnedCategoryPost();
      this.changeDataSubcription = this.queryStringService.eventChangeQueryString.subscribe(() => {
        this.getPosts();
      });
      this.queryStringService.startFetchingData();
    }
  }

  getPinnedCategoryPost() {
    this.blogService.getPinnedCategories().subscribe({
      next: (res: PostCategory[]) => {
        if (res) {
          this.category_sidebar = res;
        }
      }
    })
  }

  getPosts() {
    let body: RetriveUserModel = this.queryStringService.buildFetchingModel(this.itemsPerPage);
    this.blogService.getBlogs(body).subscribe({
      next: (res: PostPagingModel) => {
        if (res.data) {
          this.posts = res.data;
          let pagination: PaginationModel = {
            limit: body.limit,
            offset: body.offset,
            searchTerm: body.searchTerm,
            totalItems: res.total,
            sortBy: body.sortBy,
            category: "",
          };
          this.queryStringService.changePagination(pagination);
        }
      },
      error: (err: any) => {
        console.log(err);

      },
      complete: () => {

      }
    });
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  ngOnDestroy() {
    this.changeDataSubcription?.unsubscribe();
  }
}
