import { PostCategoryWithPost, PostModel } from '@/app/shared/interface/blog.interface';
import { Breadcrumb } from '@/app/shared/interface/breadcrumb';
import { BlogService } from '@/app/shared/services/blog.service';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog-static-page',
  templateUrl: './blog-static-page.component.html',
  styleUrl: './blog-static-page.component.scss'
})
export class BlogStaticPageComponent {
  @Input() postDetail: PostModel;
  
  pageType: string = 'app-blog-static-page'
  breadcrumb: Breadcrumb;
  postsByCategory = [] as PostCategoryWithPost[];
  currentRouter: string;
  constructor(
    private blogService: BlogService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.currentRouter = this.router.url;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (isPlatformBrowser(this.platformId)) {
      if (changes['postDetail']) {
        this.createBreadcrumb();
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    }
  }

  ngOnInit() {
    this.createBreadcrumb();
    this.getPostsByCategory();
  }

  createBreadcrumb(){
    this.breadcrumb = {
      title: this.postDetail.postTitle,
      items: [{ label: this.postDetail.postTitle, active: true }]
    }
  }

  getPostsByCategory() {
    this.postDetail.categories.forEach(cat => {
      let item: PostCategoryWithPost = {
        ...cat,
        posts: []
      };
      this.blogService.getPostByCategory(cat.slug).subscribe((res: PostModel[]) => {
        item.posts = res;
        this.postsByCategory.push(item);
      });
    });
  }
}
