import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { ThemeOption } from "@/app/shared/interface/common.interface";

@Injectable({
  providedIn: "root",
})
export class HomeConfigurationService {

  constructor(private http: HttpClient) {}

  getHomeConfigInfo() {
    return this.http.get<ThemeOption>(`${environment.baseURL}configuration/configuration/home-config`);
  }

  getHomeBannerInfo() {
    return this.http.get(`${environment.baseURL}configuration/configuration/home-banner`);
  }

  getVendorBannerInfo() {
    return this.http.get(`${environment.baseURL}configuration/configuration/vendor-banner`);
  }

  getCommentBannerInfo() {
    return this.http.get(`${environment.baseURL}configuration/configuration/comment-banner`);
  }

  getPopupBannerInfo() {
    return this.http.get(`${environment.baseURL}configuration/configuration/popup-banner`);
  }
}
