<ng-template #registryModal let-modal>
  <div class="registry-modal">
    <div class="modal-header justify-content-center">
      <!-- <a href="javascript:void(0)" (click)="back()"><img *ngIf="step == 1" id="login_modal_back_btn" class="back-btn" src="/assets/svg/register/back_1.svg" style="width: 1.25rem;" /></a> -->
      <h3 class="fw-semibold" style="font-weight: 700">Đăng ký thành viên</h3>
      <img
        src="/assets/svg/register/Cancel.svg"
        id="login_modal_close_btn"
        (click)="modal.dismiss('Cross click')"
        class="position-absolute"
      />
    </div>
    <form *ngIf="step == 0" [formGroup]="form" (ngSubmit)="register()">
      <div class="modal-body address-form">
        <div class="scroll-form">
          <div class="row justify-content-center p-2">
            <div class="col-12">
              <div class="input-group">
                <div
                  *ngIf="form.controls['phone'].touched && form.controls['phone'].errors?.['required']"
                  class="invalid-feedback"
                >
                  <!-- {{ "email_is_required" | translate }} -->
                  <img src="/assets/svg/register/Alarm_fill.svg" />
                  Vui lòng nhập số điện thoại
                </div>
                <div
                  *ngIf="
                    form.controls['phone'].touched && form.controls['phone'].value.length > 0 &&
                    (form.controls['phone'].value.length > 10 ||
                    form.controls['phone'].value.length < 10 ||
                    !form.controls['phone'].value.startsWith('0')
                    )
                  "
                  class="invalid-feedback"
                >
                  <img src="/assets/svg/register/Alarm_fill.svg" />
                  Số điện thọai không hợp lệ
                </div>
                <span class="input-group-text bg-white"
                  ><img
                    [src]="form.controls['phone'].touched && form.controls['phone'].errors?.['required'] ? '/assets/svg/register/Phone_red.svg' : '/assets/svg/register/Phone.svg'"
                /></span>
                <input
                  type="tel"
                  class="form-control border-start-0"
                  id="phone"
                  formControlName="phone"
                  autocomplete="off"
                  placeholder="Nhập số điện thoại *"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center p-2">
            <div class="col-12">
              <div class="input-group">
                <div
                  *ngIf="form.controls['password'].touched && form.controls['password'].errors?.['required']"
                  class="invalid-feedback"
                >
                  <img src="/assets/svg/register/Alarm_fill.svg" />
                  Vui lòng nhập mật khẩu
                </div>
                <div
                  *ngIf="form.controls['password'].touched && form.controls['password'].value.length > 0 &&
                  (
                  form.controls['password'].value.length < 6 ||
                  form.controls['password'].errors?.['pattern']
                  )"
                  class="invalid-feedback"
                >
                  <img src="/assets/svg/register/Alarm_fill.svg" />
                  Mật khẩu phải từ 6 ký tự trở lên.
                </div>
                <span class="input-group-text bg-white"
                  ><img
                    [src]="form.controls['password'].touched && form.controls['password'].errors?.['required']?'/assets/svg/register/Lock_red.svg':'/assets/svg/register/Lock.svg'"
                /></span>
                <input
                  #xe
                  type="password"
                  class="form-control border-start-0"
                  id="password"
                  formControlName="password"
                  autocomplete="new-password"
                  placeholder="Nhập mật khẩu *"
                  style="border-right: none"
                />
                <span
                  class="input-group-text"
                  style="background: white"
                  (click)="xe.type = xe.type == 'password' ? 'text' : 'password'"
                  ><img
                    #icon
                    [src]="
                      xe.type == 'password'
                        ? 'assets/svg/register/Eye_duotone.svg'
                        : 'assets/svg/register/View_hide_light.svg'
                    "
                /></span>
              </div>
            </div>
          </div>
          <div class="row justify-content-center p-2">
            <div class="col-12">
              <div class="input-group">
                <div
                  *ngIf="form.controls['repassword'].touched && form.controls['repassword'].errors?.['required']"
                  class="invalid-feedback"
                >
                  <img src="/assets/svg/register/Alarm_fill.svg" />
                  Vui lòng nhập mật khẩu
                </div>
                <div
                  *ngIf="form.controls['repassword'].touched && form.controls['repassword'].value.length > 0 &&
                  (form.controls['repassword'].value.length < 6 ||
                  form.controls['repassword'].errors?.['pattern']
                  )"
                  class="invalid-feedback"
                >
                  <img src="/assets/svg/register/Alarm_fill.svg" />
                  Mật khẩu phải từ 6 ký tự trở lên.
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="(!form.controls['repassword'].errors?.['required'] && !form.controls['password'].errors?.['required'])
                && (form.controls['repassword'].errors?.['notMatch'] || form.controls['password'].errors?.['notMatch'])"
                >
                  <img src="/assets/svg/register/Alarm_fill.svg" />
                  Mật khẩu không khớp
                </div>
                <span class="input-group-text bg-white"
                  ><img
                    [src]="
                      checkRePassword()
                        ? '/assets/svg/register/Lock_red.svg'
                        : '/assets/svg/register/Lock.svg'
                    "
                /></span>
                <input
                  #x
                  type="password"
                  class="form-control border-start-0"
                  id="repassword"
                  formControlName="repassword"
                  placeholder="Nhập lại mật khẩu *"
                  style="border-right: none"
                />
                <span
                  class="input-group-text"
                  style="background: white"
                  (click)="x.type = x.type == 'password' ? 'text' : 'password'"
                  ><img
                    #icon
                    [src]="
                      x.type == 'password'
                        ? 'assets/svg/register/Eye_duotone.svg'
                        : 'assets/svg/register/View_hide_light.svg'
                    "
                /></span>
              </div>
            </div>
          </div>
          <div class="row justify-content-center p-2">
            <div class="col-12">
              <div class="input-group">
                <div
                  *ngIf="form.controls['province'].touched && form.controls['province'].errors?.['required']"
                  class="invalid-feedback"
                >
                  <img src="/assets/svg/register/Alarm_fill.svg" />
                  Vui lòng nhập Tỉnh/ Thành phố
                </div>
                <span class="input-group-text bg-white">
                  <img
                    [src]="form.controls['province'].touched && form.controls['province'].errors?.['required']?'/assets/svg/register/Pin_alt_duotone_line_red.svg':'/assets/svg/register/Pin_alt_duotone_line.svg'"
                  />
                </span>
                <select2
                  class="form-control form-select border-start-0 select2"
                  id="province"
                  aria-label="Default select example"
                  formControlName="province"
                  placeholder="Tỉnh/Thành phố *"
                  [data]="select2Provinces"
                >
                </select2>
              </div>
            </div>
          </div>
          <div class="row justify-content-center p-2">
            <div class="col-12">
              <div class="input-group">
                <div
                  *ngIf="
                    form.controls['referer'].touched && form.controls['referer'].value.length > 0 &&
                    (form.controls['referer'].value.length > 10 ||
                    form.controls['referer'].value.length < 10 ||
                    !form.controls['referer'].value.startsWith('0')
                    )
                  "
                  class="invalid-feedback"
                >
                  <img src="/assets/svg/register/Alarm_fill.svg" />
                  Số điện thoại của người giới thiệu không hợp lệ
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="form.controls['referer'].value.length > 0 && form.controls['referer'].value == form.controls['phone'].value"
                >
                  <img src="/assets/svg/register/Alarm_fill.svg" />
                  Số điện thoại của người giới thiệu không được trùng với số điện thoại đăng ký
                </div>
                <span class="input-group-text bg-white"
                  ><img
                    [src]="form.controls['referer'].value.length > 0 && form.controls['referer'].errors?.['required'] ? '/assets/svg/register/Phone_red.svg' : '/assets/svg/register/Phone.svg'"
                /></span>
                <input
                  type="tel"
                  class="form-control border-start-0"
                  id="referer"
                  formControlName="referer"
                  autocomplete="off"
                  placeholder="Nhập số điện thoại của người giới thiệu"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center p-2 mx-1">
        <div class="col-12">
          <div class="forgot-box">
            <div
              class="form-check ps-0 m-0 remember-box d-flex align-items-center"
            >
              <input
                class="checkbox_animated check-box"
                type="checkbox"
                id="flexCheckDefault"
                [formControl]="tnc"
              />
              <label
                class="form-check-label d-flex align-items-center"
                for="flexCheckDefault"
              >
                <span [class.text-red]="this.tnc.errors?.['required']">
                  Tôi đã đọc và đồng ý với
                  <a href="/dieu-khoan-su-dung">Điều khoản sử dụng</a>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-submit row justify-content-center p-2">
        <div class="col-8 text-center">
          <app-button [id]="'sign_up_btn'" style="border-radius: 40px"
            >Đăng ký</app-button
          >
        </div>
      </div>
      <div class="btn-submit row justify-content-center p-2">
        <label class="text-center" for="flexCheckDefault">
          Bạn đã có tài khoản? Vui lòng
          <a href="auth/login"
            >Đăng nhập tại đây</a
          >
        </label>
      </div>
    </form>
    <div *ngIf="step == 1">
      <div class="pt-3 d-grid gap-1 text-center">
        <span class="fw-bold" style="color: #015D2C; font-size: 1.5rem;">Xác thực OTP</span>
        <span>Mã xác thực (OTP) đã được gửi qua SMS số</span>
        <span class="fw-bold" style="font-size: 1.25rem;">{{ phoneNo }}</span>
        <div class="pt-2">
          <span class="d-flex justify-content-center align-items-center">Không nhận được mã xác minh?&nbsp;
            <span *ngIf="second > 0" class="fw-bold" style="color: #999999;">Gửi lại sau <span id="second"></span>s</span>
            <button *ngIf="second == 0" class="btn btn-resend-otp fw-bold" (click)="getOTP(true)">Gửi lại mã</button>
          </span>
        </div>
      </div>
      <div class="pt-4 d-flex justify-content-center gap-3">
        <!-- <div class="col-2" *ngFor="let i of [0, 1, 2, 3]">
          <input type="number" [id]="'otp_' + i" class="form-control text-center opt-input" maxlength="1" (keydown)="onKeyDown($event, i)" (keypress)="onKeyPress($event, i)" (keyup)="onKeyUp($event, i)" />
        </div> -->
        <div>
          <ng-otp-input  (onInputChange)="onOtpChange($event)" [config]="otpOptions"></ng-otp-input>
          <div class="pt-3 text-center" *ngIf="!isValidatedOTP">
            <span style="color: #BC0000;">Mã OTP không chính xác. Vui lòng thử lại!</span>
          </div>
        </div>
      </div>
      <div class="py-4 text-center">
        <span>Bạn đã có tài khoản? Vui lòng</span>&nbsp;
        <a class="fw-bold" href="javascript:void(0)" (click)="openLogin()">Đăng nhập tại đây</a>
      </div>
    </div>
  </div>
</ng-template>
