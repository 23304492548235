import { Component, ElementRef, HostListener, Input, TransferState, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import * as feather from 'feather-icons';

import { AuthService, TOKEN, USERNAME } from '@/app/shared/services/auth.service';
import { CommonService } from '@/app/shared/services/common.service';
import { SignalrService } from '../../services/notification.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { PushNotificationService } from '../../services/push-notification.service';

@Component({
  selector: 'app-nav-noti',
  templateUrl: './nav-noti.component.html',
  styleUrls: ['./nav-noti.component.scss'],
})
export class NavNotiComponent {
  @Input() style: string = 'basic';

  @Input() isAuthenticated: boolean = false;
  user: any;
  username: any = '';
  selectedTab: number = 1;
  isShowNoti: boolean = false;

  @ViewChild('notificationDropdown') notificationDropdown: ElementRef;
  @ViewChild('notificationIcon') notificationIcon: ElementRef;

  public notiProducts = [
    {
      "time": "1 giờ trước ",
      "date": "24/01/2024",
      "content": "Đơn hàng admin#20 đã được hoàn thành, xem ngay",
      "url": "/account/my-order/admin#20"
    },
    {
      "time": "2 giờ trước ",
      "date": "22/01/2024",
      "content": "Sản phẩm ABC đã có sự tăng giá bán so với lúc bạn để vào giỏ hàng, vui lòng kiểm tra lại trước khi thanh toán",
      "url": "/cart"
    },
    {
      "time": "5 giờ trước ",
      "date": "20/01/2024",
      "content": "Sản phẩm ABC đã có sự giảm giá bán so với lúc bạn để vào giỏ hàng, kiểm tra ngay để đạt được giá tốt",
      "url": "/cart"
    },
    {
      "time": "5 giờ trước ",
      "date": "20/01/2024",
      "content": "Với mỗi mốc giá trị sản phẩm ksksks trong đơn hàng. Quý khách sẽ được lựa chọn 1 trong 3 gói quà tặng",
      "url": "/cart"
    },
  ];

  public promotionNoti = [
    {
      "time": "1 giờ trước ",
      "content": "Với mỗi mốc giá trị sản phẩm Dược Vương trong đơn hàng. Quý khách sẽ được lựa chọn 1 trong 3 gói quà tặng",
      "url": "/account/my-voucher"
    },
    {
      "time": "2 giờ trước ",
      "content": "Với mỗi mốc giá trị sản phẩm Durex trong đơn hàng. Quý khách sẽ được lựa chọn 1 trong 3 gói quà tặng",
      "url": "/account/my-voucher"
    },
    {
      "time": "5 giờ trước ",
      "content": "Với mỗi mốc giá trị sản phẩm ksksks trong đơn hàng. Quý khách sẽ được lựa chọn 1 trong 3 gói quà tặng",
      "url": "/account/my-voucher"
    },
  ];

  public notificationCount: number = 0;//this.notiProducts.length + this.promotionNoti.length;
  private notiChangeSub?: Subscription;
  constructor(
    private authService: AuthService,
    private commonService: CommonService,
    private signalrService: SignalrService,
    private cookieService: SsrCookieService,
    private router: Router,
    private transferState: TransferState,
    private pushNotiService: PushNotificationService,
  ) {
    this.username = cookieService.get('username');
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (this.notificationDropdown && !this.notificationDropdown.nativeElement.contains(event.target)
      && !this.notificationIcon.nativeElement.contains(event.target)) {
      this.toggle(false);
    }
  }

  ngOnInit() {
    const token = this.transferState.get(TOKEN, '');

    let jwtToken = this.commonService.parseJwt(token!);
    this.isAuthenticated = this.authService.isAuthenticated();
    this.commonService.authEvent.subscribe({
      next: (data: boolean) => {
        this.isAuthenticated = data;
        if (this.isAuthenticated) {
          this.username = this.transferState.get(USERNAME, '');
          // this.username = this.cookieService.get('username');
        }
      },
    });

    this.notiChangeSub = this.commonService.notificationChange$.subscribe({
      next: () => {
        this.countUnreadNoti();
      }
    })
  }

  countUnreadNoti() {
    this.pushNotiService.getUnreadNotification().subscribe({
      next: (total: number) => {
        this.notificationCount = total;
      }
    })
  }

  logout(value: boolean) {
    if (value) {
      this.authService.logout();
      this.router.navigateByUrl("/", { replaceUrl: true });
    }
  }

  goToNotiPage(){
    this.router.navigateByUrl("/thong-bao");
  }

  toggle(val?: any) {
    if (val) {
      this.isShowNoti = val;
    }
    else {
      this.isShowNoti = !this.isShowNoti;
    }
  }

  selectTab(tab: number): void {
    this.selectedTab = tab;
  }
  ngOnDestroy() {
    this.notiChangeSub?.unsubscribe();
  }
}
