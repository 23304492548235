import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { GetCodeRequestModel, GetCodeResponseModel, Segment, SpinModel, VerifyCodeResponse, Wheel } from "../interface/wheel.interface";
import { BaseResponse } from "../interface/common.interface";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WheelService {

  wheel: BehaviorSubject<Wheel> = new BehaviorSubject<Wheel>({});
  public getWheelConfig = this.wheel.asObservable();
  public setWheelConfig(wheel: Wheel) {
    this.wheel.next(wheel);
  }

  constructor(private http: HttpClient) { }

  getWheel() {
    return this.http.get<BaseResponse>(`${environment.baseURL}minigame/wheel/retrieve-available-wheel`);
  }

  spin(model: SpinModel) {
    return this.http.post<BaseResponse>(`${environment.baseURL}minigame/wheel/spin`, model);
  }

  verifyCode(code: string) {
    return this.http.get<VerifyCodeResponse>(`${environment.baseURL}minigame/wheel/verify-code?code=${code}`);
  }

  getAdminWheel() {
    const keyObjectString = localStorage.getItem('adminKey');
    if (keyObjectString) {
      const keyObject = JSON.parse(keyObjectString);
      return this.http.get<Wheel>(`${environment.baseURL}api/admin/wheel`, {
        headers: {
          "Authorization": keyObject.key
        },
      });
    }
    return Promise.reject({ status: 401, message: 'Unauthorized' });
  }

  generateCodes(numberOfCode: number) {
    const body = {
      numberOfCode: numberOfCode ?? 1,
    };
    const keyObjectString = localStorage.getItem('adminKey');
    if (keyObjectString) {
      const keyObject = JSON.parse(keyObjectString);
      return this.http.post<string[]>(`${environment.baseURL}api/admin/wheel/generate-code`, body, {
        headers: {
          "Authorization": keyObject.key
        }
      });
    }
    return Promise.reject({ status: 401, message: 'Unauthorized' });
  }

  getCodes(payload: GetCodeRequestModel) {
    const keyObjectString = localStorage.getItem('adminKey');
    if (keyObjectString) {
      const keyObject = JSON.parse(keyObjectString);
      return this.http.post<GetCodeResponseModel>(`${environment.baseURL}api/admin/wheel/get-codes`, payload, {
        headers: {
          "Authorization": keyObject.key
        },
      });
    }
    return Promise.reject({ status: 401, message: 'Unauthorized' });
  }

  getRemainingSpins(id: string) {
    return this.http.get<BaseResponse>(`${environment.baseURL}minigame/wheel/spin-remaining`);
  }

  getHistory(wheelId: string) {
    return this.http.get<BaseResponse>(`${environment.baseURL}minigame/wheel/spin-history/${wheelId}`);
  }
}
