<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<div class="container-fluid-lg mx-auto">
    <div class="row">
        <div class="col-sm-12 col-xs-12 pb-sm-3 pb-xs-3 col-md-12">
            <div class="bg-white rounded-3 pb-5 container">
                <div class="notification-setting" *ngIf="data.length">
                    <div [class.unread]="!n.isRead" class="notification-item p-2 mb-2 rounded-2" *ngFor="let n of data" (click)="viewNotificationDetail(n)">
                        <h4>{{ n.title }}</h4>
                        <div><i class="ri-time-line"></i> {{ dateTimeFormat(n.createdAt) }}</div>
                        <p class="noti-messsage">{{n.message}}</p>
                    </div>
                </div>
                <nav class="custome-pagination">
                    <app-collection-paginate></app-collection-paginate>
                </nav>
            </div>
        </div>
    </div>
</div>