import { Component, HostListener, Inject, Input, Output, EventEmitter, Optional, PLATFORM_ID, TransferState, ViewChild, afterNextRender } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { PinTagContent } from '@/app/shared/interface/common.interface';
import { Option } from '@/app/shared/interface/common.interface';
import { LoginModalComponent } from '@/app/shared/widgets/login-modal/login-modal.component';
import { RegistryModalComponent } from '@/app/shared/widgets/registry-modal/registry-modal.component';
import { AuthService, IS_AUTHENTICATED } from '@/app/shared/services/auth.service';
import { CommonService } from '@/app/shared/services/common.service';
import { Router } from '@angular/router';
import { ForgotPasswordModalComponent } from "@/app/shared/widgets/forgot-password-modal/forgot-password-modal.component";
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { REQUEST } from '@/express.tokens';
import { AccountService } from '@/app/shared/services/account.service';
import { User } from '@/app/shared/interface/user.interface';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss'
})
export class NavBarComponent {

  @Input() data: Option;
  @Input() sticky: boolean | number | undefined; // Default false
  @Input() pin_tags: PinTagContent[] = [];

  @ViewChild("loginModal") LoginModal: LoginModalComponent;
  @ViewChild("registryModal") RegistryModal: RegistryModalComponent;
  @ViewChild("forgotPasswordModal") ForgotPasswordModal: ForgotPasswordModalComponent;

  public stick: boolean = false;
  @Input() active: boolean = false;
  public showLoginPopup: boolean = false;

  public activePushNotification = false;

  isAuthenticated: boolean = false;

  isBrowser: boolean = false;

  open: boolean = false;

  constructor(
    private authService: AuthService,
    public commonService: CommonService,
    private accountService: AccountService,
    @Inject(PLATFORM_ID) private platformId: Object) {
    this.isAuthenticated = this.authService.isAuthenticated();

    afterNextRender(() => {
      this.commonService.getShowLoginPopup.subscribe({
        next: (data: boolean) => {
          this.showLoginPopup = data;
          if (this.showLoginPopup) {
            this.LoginModal.openModal();
          }
        },
      })
    });

    // const localStorage = this.document.defaultView?.localStorage;
    // const token = this.cookieService.get('token');
    // const userName = this.cookieService.get('username');
    // let jwtToken = this.commonService.parseJwt(token!);
    // this.isAuthenticated = (jwtToken && jwtToken.UserName == userName!) ? true : false;
    // if (this.isAuthenticated) {
    //   this.refreshTokenIfNeeded();
    // }

    this.commonService.authEvent.subscribe({
      next: (data: boolean) => {
        this.isAuthenticated = data;
      },
    });
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) { // For SSR
      let number = window.screenY || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (number >= 167) {
        this.stick = true;
      } else {
        this.stick = false;
      }
    }
  }
  ngOnInit() {
    this.isBrowser = isPlatformBrowser(this.platformId);

    this.commonService.getOpenMobileMenu.subscribe({
      next: (val: any) => {
        if (val) {
          this.open = val;
        }
      }
    });

    this.accountService.getUserData.subscribe({
      next: (user: User) => {
        if (user && user.isActivePushNotification) {
          this.activePushNotification = true;
        } else {
          this.activePushNotification = false;
        }
      }
    })
  }

  // refreshTokenIfNeeded() {
  //   if (this.authService.isAccessTokenExpiredOrCloseToExpiry()) {
  //     const refreshToken = {
  //       refreshToken: localStorage.getItem('refreshToken')
  //     };

  //     if (refreshToken) {
  //       this.authService.refreshToken(JSON.stringify(refreshToken)).subscribe({
  //         next: (response: any) => {
  //           console.log('Token refreshed successfully');
  //           this.authService.updateToken(response.jwtToken, response.refreshToken);
  //         },
  //         error: (error) => {
  //           console.error('Token refresh failed', error);
  //           this.authService.logout();
  //           this.router.navigateByUrl('/auth/login', { replaceUrl: true });
  //         }
  //       });
  //     } else {
  //       console.error('No refresh token available');
  //       this.authService.logout();
  //       this.router.navigateByUrl('/auth/login', { replaceUrl: true });
  //     }
  //   }
  // }

  toggle(val?: any) {
    if (val != null) {
      this.active = val;
    }
    else {
      this.active = !this.active;
    }
  }
  openRegistryModal(event: any) {
    if (event) {
      this.RegistryModal.openModal();
    }
  }

  openLoginModal(event: any) {
    if (event) {
      this.LoginModal.openModal();
    }
  }

  openForgotPasswordModal(event: any) {
    if (event) {
      this.ForgotPasswordModal.openModal();
    }
  }

  openMenu(value: any) {
    this.open = value;
  }

}
