import { RetriveUserModel } from "./product.interface";

export class NotificationDto {
    id: string;
    title: string;
    message: string;
    createdAt: string;
    type: NotificationDataType;
    topicIds: string[] | null;
    dataDetail: string | null;
    isRead: boolean;
    readAt: string | null;
    data: string | null;
    topicNames: string[] | null;

    static getNotiTargetUrl(noti: NotificationDto): string {
        if (noti.data) {
            let data = JSON.parse(noti.data);
            if (noti.type === NotificationDataType.UpdateOrder) {
                return 'account/my-order/' + encodeURIComponent(data.OrderId);
            } else if (noti.type === NotificationDataType.UpdateUser) {
                return 'account/profile';
            }
        }
        return '';
    }
}
export enum NotificationDataType {
    NewUser = 1,
    NewOrder = 2,
    UpdateUser = 3,
    UpdateOrder = 4
}

export enum NotificationStatusType {
    Actived = 1,
    Deleted = 99
}
export class NotificationRequestModel extends RetriveUserModel {

}