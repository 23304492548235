<div *ngIf="wishlist?.length" class="p-4 bg-white rounded-3 mobile-pd">
  <div class="title-header">
    <div class="d-flex align-wishlist-center w-100">
      <div class="input-group align-wishlist-center border div-search">
        <input id="quiclSearchInput" type="search" class="form-control border-0 bg-transparent search-input"
          placeholder="Bạn đang tìm sản phẩm gì?" [(ngModel)]="inputText" (keyup)="input()" />
        <i class="ri-search-line d-flex justify-content-center align-items-center search-icon"></i>
      </div>
    </div>
  </div>
  <div>
    <ng-container *ngIf="skeletonLoader && !wishlist.length; else productContent">
      <app-skeleton-product-box *ngFor="let item of skeletonItems"></app-skeleton-product-box>
    </ng-container>
    <ng-template #productContent>
      <div class="cart-table p-0" *ngIf="wishlist.length > 0">
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr class="product-box-contain" *ngFor="let item of wishlist">
                <td class="product-detail ps-0 pe-0 col-7">
                  <div>
                    <div class="product border-0">
                      <a [routerLink]="['/product/', item.slug]" class="product-image">
                        <img [src]="
                            item.imageUrls.length > 0
                              ? item.imageUrls[0]
                              : 'assets/images/product.png'
                          " class="img-fluid" alt="product" />
                      </a>
                      <div class="product-detail">
                        <ul class="d-grid gap-3">
                          <li class="name">
                            <a [routerLink]="['/product/', item.slug ]" class="link" title="{{ item.name }}">
                              <div>{{ item.name }}</div>
                            </a>
                            <div class="d-flex gap-2 justify-content-between">
                              <div class="d-flex flex-wrap gap-2 pt-2">
                                <ng-container *ngFor="let l of item.labels; let i = index">
                                  <div *ngIf="l.slug" class="product-label {{ i % 2 == 0 ? 'best-seller' : 'gift' }}">
                                    <div type="button" (click)="queryStringBuilder(l.slug)">
                                      <h6>{{ l.name }}</h6>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                              <span
                                *ngIf="item.pricingTablePrice < item.basePrice && item.stockStatus != 8 && item.basePrice > 0"
                                class="best-seller mobile-lable product-label d-lg-none d-xl-none bg-warning">
                                <h6 class="fw-bold text-danger">
                                  {{
                                  -(item.basePrice - item.pricingTablePrice) /
                                  item.basePrice | percent
                                  }}
                                </h6>
                              </span>
                            </div>
                            <div *ngIf="item.wholesalePrices?.length"
                              class="d-none d-xl-flex d-lg-flex flex-wrap wsp px-2 py-1 mt-2 gap-3">
                              <ng-container *ngFor="let wsp of item.wholesalePrices; let i = index">
                                <div class="d-flex gap-1">
                                  <h6>mua từ {{ wsp.minQuantity }}:</h6>
                                  <h6 class="text-danger fw-bolder">{{ wsp.wholesalePrice | currencySymbol }}</h6>
                                </div>
                                <div *ngIf="i < item.wholesalePrices.length - 1" class="my-1"
                                  style="border: 1px solid var(--theme-color);"></div>
                              </ng-container>
                            </div>
                            <div class="d-flex">
                              <ng-container>
                                <div class="d-flex gap-1 d-none d-xl-flex d-lg-flex flex-wrap wsp px-2 py-1 mt-2 gap-3"
                                  *ngIf="item.productOrderLimit?.maxOrderQuantityPerOrder">
                                  <h6>mua tối đa {{ item.productOrderLimit.maxOrderQuantityPerOrder }} trên đơn</h6>
                                </div>
                                <div class="d-flex gap-1 d-none d-xl-flex d-lg-flex flex-wrap wsp px-2 py-1 mt-2 gap-3"
                                  *ngIf="item.productOrderLimit?.maxOrderQuantityPerDay">
                                  <h6>mua tối đa {{ item.productOrderLimit.maxOrderQuantityPerDay }} trong ngày</h6>
                                </div>
                              </ng-container>
                            </div>
                            <div class="d-flex align-items-center gap-2 pt-2 d-lg-none d-xl-none"
                              *ngIf="item.stockStatus != 8 && item.basePrice > 0">
                              <h6 class="theme-color float-end" *ngIf="item.pricingTablePrice < item.basePrice">
                                <del class="text-content">
                                  {{ item.basePrice | currencySymbol }}
                                </del>
                              </h6>
                              <h5 class="price fw-bold float-end"
                                class="{{item.pricingTablePrice < item.basePrice?'text-danger':'theme-color'}}">
                                {{ item.pricingTablePrice | currencySymbol }}
                              </h5>
                            </div>
                            <div class="d-lg-none d-xl-none d-flex gap-2 justify-content-between">
                              <div class="out-of-stock-label" *ngIf="item.basePrice <= 0">
                                <h5 *ngIf="item.stockStatus < 8" class="out-of-stock-text pt-2" style="color: red;">Sản
                                  phẩm tạm thời hết hàng</h5>
                                <a routerLink="/account/my-business-profile">
                                  <h5 *ngIf="item.stockStatus == 8" class="out-of-stock-text pt-2" style="color: red;">
                                    Vui lòng hoàn thiện hồ sơ để xem giá</h5>
                                </a>
                              </div>
                              <div class="out-of-stock-label" *ngIf="item.basePrice > 0">
                                <a routerLink="/account/my-business-profile">
                                  <h5 *ngIf="item.stockStatus == 9" class="out-of-stock-text pt-2" style="color: red;">
                                    Vui lòng hoàn thiện hồ sơ để đặt hàng</h5>
                                </a>
                              </div>
                            </div>
                            <div class="d-lg-none d-xl-none pt-2 justify-content-end">
                              <button class="border-0 bg-none" (click)="openModal(item)">
                                <img src="assets/svg/recycle-bin 3.svg" />
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="d-lg-none d-xl-none d-flex justify-content-between align-items-center">
                    <div class="p-2 d-flex mt-2 gap-3 flex-wrap">
                      <ng-container *ngFor="let wsp of item.wholesalePrices; let i = index">
                        <div>
                          <h6>mua từ {{ wsp.minQuantity }}</h6>
                          <h6 class="text-danger fw-bolder">{{ wsp.wholesalePrice | currencySymbol }}</h6>
                        </div>
                        <div *ngIf="i < item.wholesalePrices.length - 1" class="my-1"
                          style="border: 1px solid var(--theme-color);"></div>
                      </ng-container>

                    </div>
                    <div class="p-2 d-flex mt-2 gap-3 flex-wrap">
                      <ng-container>
                        <div class="border p-1" *ngIf="item.productOrderLimit?.maxOrderQuantityPerOrder??0 > 0">
                          <h6>tối đa {{ item.productOrderLimit.maxOrderQuantityPerOrder }} trên đơn</h6>
                        </div>
                        <div class="border p-1" *ngIf="item.productOrderLimit?.maxOrderQuantityPerDay??0 > 0">
                          <h6>tối đa {{ item.productOrderLimit.maxOrderQuantityPerDay }} trong ngày</h6>
                        </div>
                      </ng-container>
                    </div>
                    <div class="quantity-price div-col pt-2" style="min-height: auto; max-width: 50%;">
                      <div class="cart_qty">
                        <div class="input-group">
                          <app-counter [cartItem]="item.cartItem" style="width: 100%"></app-counter>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="price col-2 d-none d-lg-table-cell d-xl-table-cell">
                  <div class="mobile d-flex justify-content-end" *ngIf="item.stockStatus != 8 && item.basePrice > 0">
                    <div class="my-auto">
                      <h5 class="float-start d-none d-lg-flex d-xl-flex align-items-center gap-2 float-end">
                        <span *ngIf="item.pricingTablePrice < item.basePrice"
                          class="fw-bold text-danger bg-warning px-2 py-1 rounded-4">
                          {{ -(item.basePrice-item.pricingTablePrice)/item.basePrice | percent }}
                        </span>
                        <h5 class="price fw-bold float-end"
                          class="{{item.pricingTablePrice < item.basePrice?'text-danger':'theme-color'}}">
                          {{ item.pricingTablePrice | currencySymbol }}
                        </h5>
                      </h5>
                      <h6 class="theme-color float-end" *ngIf="item.pricingTablePrice < item.basePrice">
                        <del class="text-content">
                          {{ item.basePrice | currencySymbol }}
                        </del>
                      </h6>
                    </div>
                  </div>
                </td>
                <td class="quantity col-2 d-none d-lg-table-cell d-xl-table-cell">
                  <div class="d-flex justify-content-center"
                    *ngIf="item.stockStatus != 8 && item.stockStatus != 9 && item.basePrice > 0 else warning">
                    <div class="quantity-price div-col">
                      <div class="cart_qty">
                        <div class="input-group">
                          <app-counter [cartItem]="item.cartItem" style="width: 100%"></app-counter>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-template #warning>
                    <div>
                      <div class="out-of-stock-label" *ngIf="item.basePrice <= 0">
                        <h5 *ngIf="item.stockStatus < 8" class="out-of-stock-text" style="color: red;">Sản phẩm tạm thời
                          hết hàng</h5>
                        <a routerLink="/account/my-business-profile">
                          <h5 *ngIf="item.stockStatus == 8" class="out-of-stock-text" style="color: red;">Vui lòng hoàn
                            thiện hồ sơ để xem giá</h5>
                        </a>
                      </div>
                      <div class="out-of-stock-label" *ngIf="item.basePrice > 0">
                        <a routerLink="/account/my-business-profile">
                          <h5 *ngIf="item.stockStatus == 9" class="out-of-stock-text" style="color: red;">Vui lòng hoàn
                            thiện hồ sơ để đặt hàng</h5>
                        </a>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-template>
    <app-collection-paginate> </app-collection-paginate>
  </div>
</div>
<app-no-data [class]="'no-data-added'" [image]="'assets/svg/empty-items.svg'" [text]="'Bạn chưa có sản phẩm yêu thích!'"
  [description]="'Hãy thêm sản phẩm bạn yêu thích nhé!'" *ngIf="!wishlist.length">
</app-no-data>

<app-confirmation-modal #confirmModal [message]="'Bạn có chắc muốn loại bỏ sản phẩm này khỏi danh sách yêu thích?'"
  (confirmed)="confirmDelete($event)">
</app-confirmation-modal>