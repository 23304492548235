import { Component, Inject, PLATFORM_ID, TransferState } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './shared/services/common.service';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from './shared/services/seo.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { isDevMode } from '@angular/core';
import { environment } from '@/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { firstValueFrom } from 'rxjs';
import { CLIENT_IP } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'ecrms-frontend-angular';

  constructor(
    // private translateService: TranslateService,
    private commonService: CommonService,
    private meta: Meta,
    private titleService: Title,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private deviceService: DeviceDetectorService,
    private transferState: TransferState,
  ) {
    // this.translateService.setDefaultLang('en');
    // this.translateService.use('en');
  }

  async ngOnInit() {
    // this.seoService.generateTags({});

    if (isPlatformBrowser(this.platformId)) {
      this.commonService.deviceInfo = this.deviceService.getDeviceInfo();
    }

    this.meta.addTag({
      name: "keywords",
      property: "keywords",
      content: "Thuốc, Dược phẩm, Mỹ phẩm, Thiết bị y tế, Dược Vương",
    });
    this.meta.addTag({
      name: "author",
      property: "author",
      content: "Giá thuốc tốt",
    });
    this.meta.addTag({
      name: "robots",
      content: "index, follow, max-snippet:-1, max-image-preview:standard, max-video-preview:-1",
    });

    if (isPlatformBrowser(this.platformId)) {
      if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        this.meta.updateTag({
          name: 'viewport',
          content: "width=device-width, initial-scale=1, maximum-scale=1"
        });
      }
    }

    this.commonService.getTitle.subscribe({
      next: (title: string) => {
        if (title) {
          this.titleService.setTitle(title);
        }
        else {
          this.titleService.setTitle("Giá thuốc Tốt - “Nhanh – Hiệu quả – Tiện lợi”");
        }
      }
    });

    if (this.isDevelopmentMode()) {
      this.document.documentElement.style.setProperty('--theme-color', '#698B69');
      this.document.documentElement.style.setProperty('--theme-color-linear', '#698B69');
    } else {
    }
  }

  isDevelopmentMode(): boolean {
    return !environment.production;
  }

}
