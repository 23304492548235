import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "@/environments/environment";
import { BaseResponseModel } from "../interface/base-response.interface";
import { NotificationDto, NotificationRequestModel } from "../interface/notification.interface";
import { PagingResponseModel } from "../interface/pagination.interface";

@Injectable({
    providedIn: "root",
})
export class PushNotificationService {
    constructor(private http: HttpClient) { }

    subscribeToken(token: string): Observable<BaseResponseModel> {
        let model = {
            'token': token
        };
        localStorage.setItem("FcmToken", token);
        return this.http.post<BaseResponseModel>(`${environment.baseURL}notification/topic/user-subscribe`, model, {
            headers: {
                "Content-Type": "application/json"
            },
        });
    }
    removeToken(): Observable<BaseResponseModel> {
        let token = localStorage.getItem("FcmToken");
        if (token) {
            let model = {
                'token': token,
            };
            localStorage.removeItem("FcmToken");
            return this.http.delete<BaseResponseModel>(`${environment.baseURL}notification/topic/remove-token`, {
                headers: {
                    "Content-Type": "application/json"
                },
                body: model,
            });
        } else {
            let result = {
                isSuccess: false,
                message: "Token not found",
                statusCode: 404,
            } as BaseResponseModel;
            return of(result);
        }
    }
    getLastestNotifications(): Observable<PagingResponseModel<NotificationDto>> {
        let body: NotificationRequestModel = {
            limit: 5,
            offset: 0,
        }
        return this.http.post<PagingResponseModel<NotificationDto>>(`${environment.baseURL}notification/notification/get-notifications`, body);
    }
    getNotifications(body: NotificationRequestModel): Observable<PagingResponseModel<NotificationDto>> {
        return this.http.post<PagingResponseModel<NotificationDto>>(`${environment.baseURL}notification/notification/get-notifications`, body);
    }
    readNotification(notiId: string): Observable<boolean> {
        return this.http.post<boolean>(`${environment.baseURL}notification/notification/read-notification/${notiId}`, null);
    }
    getNotification(notiId: string): Observable<NotificationDto> {
        return this.http.get<NotificationDto>(`${environment.baseURL}notification/notification/get-notification/${notiId}`);
    }
    getUnreadNotification(): Observable<number> {
        return this.http.get<number>(`${environment.baseURL}notification/notification/count-unread-notification`, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
}

