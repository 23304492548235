<div class="d-flex" *ngIf="cartItem">
  <app-button
    *ngIf="!isUpdating"
    [class]="'btn qty-left-minus'"
    [id]="'add-to-cart' + cartItem.productID"
    [spinner]="false"
    (click)="onQuantityChange(cartItem, -1)"
  >
    <span>
      <i
        class="ri-subtract-line"
        *ngIf="cartItem.quantity > 1 || cartItem.quantity == 0"
      ></i>
      <i class="ri-delete-bin-line" *ngIf="cartItem.quantity == 1"></i>
    </span>
  </app-button>
  <input
    *ngIf="!isUpdating"
    class="form-control input-number qty-input"
    type="number"
    name="quantity"
    [(ngModel)]="cartItem.quantity"
    (change)="onQuantityChange(cartItem, 0,$event)"
  />
  <div class="form-control bg-transparent border-0 d-flex justify-content-center align-items-center" *ngIf="isUpdating">
    <div class="loader"></div>
  </div>
  <app-button
    *ngIf="!isUpdating"
    [class]="'btn qty-left-minus'"
    [id]="'qty-left-minus' + cartItem.productID"
    [spinner]="false"
    (click)="onQuantityChange(cartItem, 1)"
  >
    <i class="ri-add-line"></i>
  </app-button>
</div>