import { Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { Select, Store } from '@ngxs/store';
import { Observable, Subject, of } from 'rxjs';
import { switchMap, mergeMap, takeUntil } from 'rxjs/operators';
// import { ViewOrder } from '../../../../shared/action/order.action';
// import { GetOrderStatus } from '../../../../shared/action/order-status.action';
// import { OrderState } from '../../../../shared/state/order.state';
// import { OrderStatusState } from '../../../../shared/state/order-status.state';
import { Order, OrderModel, OrderStatus, PaymentMethod, ReOrderResponseModel } from '@/app/shared/interface/order.interface';
import { OrderService } from '@/app/shared/services/order.service';
// import { OrderStatusModel } from '../../../../shared/interface/order-status.interface';
// import $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { CartService } from '@/app/shared/services/cart.service';
import { BankInfo, QRData } from '@/app/shared/interface/qrcode.interface';
import { QRCodeService } from '@/app/shared/services/qrcode.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-order-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})


export class OrderDetailsComponent {



  // @Select(OrderStatusState.orderStatus) orderStatus$: Observable<OrderStatusModel>;

  private destroy$ = new Subject<void>();

  public order: Order;

  headerColumns = [
    "Sản phẩm",
    "Số lượng khách đặt",
    "Số lượng giao thực tế",
    "Đơn giá (đ)",
    "Thành tiền (đ)"
  ];

  baseUrl = 'https://qr.sepay.vn/img';
  qrImgSrc: string;
  qrData: QRData = {
    accNo: '',
    accName: '',
    bank: '',
    amount: 0,
    des: ''
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private toastService: ToastrService,
    private cartService: CartService,
    private qrCodeService: QRCodeService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    // this.store.dispatch(new GetOrderStatus());
  }

  ngOnInit() {
    this.route.params.subscribe({
      next: (res: any) => {
        if (res["id"]) {
          this.getOrderDetail(res["id"]);
        }
      }
    })
  }

  getOrderDetail(orderId: string) {
    const body = {
      orderId: orderId
    }
    this.orderService.getOrders(JSON.stringify(body)).subscribe({
      next: (res: OrderModel) => {
        if (res && res.orderHistory) {
          const o = res.orderHistory.find(o => o.orderId === orderId);
          if (o) {
            this.order = o;
            const paymentMethods: PaymentMethod[] = [
              { value: 'bank', label: 'Chuyển khoản ngân hàng' },
              { value: 'cash', label: 'Thanh toán bằng tiền mặt khi nhận hàng' },
            ];

            const paymentMethod = paymentMethods.find(pm => pm.value === this.order.paymentMethod);

            if (paymentMethod) {
              this.order.paymentMethod = paymentMethod.label;
              this.getBankInfo();
            } else {
              console.error('Phương thức thanh toán không hợp lệ:', this.order.paymentMethod);
            }
          }
        }
      }
    });
  }

  getBankInfo() {
    this.qrCodeService.getBankInfo().subscribe({
      next: (res: BankInfo) => {
        if (res) {
          this.qrData.accNo = res.bankNumber;
          this.qrData.accName = res.bankName;
          this.qrData.bank = res.bankCode;
          this.qrData.amount = this.order.finalPrice;
          this.qrData.des = `${res.prefix}${this.order.globalOrderId}`;

          this.qrImgSrc = `${this.qrCodeService.baseUrl}?acc=${this.qrData.accNo}&bank=${this.qrData.bank}&amount=${this.qrData.amount}&des=${this.qrData.des}`;
        }
      },
      error: (err) => console.log(err)
    });
  }

  copyToClipboard(value: string) {
    if (isPlatformBrowser(this.platformId)) {
      navigator.clipboard.writeText(value);
    }
    this.toastService.success("Sao chép mã đơn hàng thành công!", "", { positionClass: "toast-top-right" });
  }

  getOrderStatus(status: OrderStatus) {
    switch (status) {
      case OrderStatus.Created:
        return { value: "Đang xác nhận", icon: "created" };
      case OrderStatus.Progressing:
        return { value: "Đang xử lý", icon: "progressing" };
      // case OrderStatus.Packing:
      //   return { value: "Đã Đóng Gói", icon: "progressing"}
      case OrderStatus.Shipping:
        return { value: "Đang vận chuyển", icon: "shipping" };
      case OrderStatus.Done:
        return { value: "Đã Hoàn Thành", icon: "done" };
      case OrderStatus.Cancelled:
        return { value: "Đã hủy", icon: "cancelled" };
      case OrderStatus.Paid:
        return { value: "Đã thanh toán", icon: "paid" };
      case OrderStatus.PartialPaid:
        return { value: "Thanh toán một phần", icon: "partial-paid" };
    }
  }

  reOrder() {
    this.orderService.reOrder(this.order.orderId).subscribe({
      next: (res: ReOrderResponseModel) => {
        if (res) {
          if (res.isSuccess) {
            this.toastService.success("Đã thêm các sản phẩm vào giỏ hàng");
            if (res.data.length) {
              this.toastService.info(
                "Một số sản phẩm trong đơn hàng không còn khả dụng (tạm hết hàng hoặc dừng bán), vui lòng kiểm tra lại giỏ hàng trước khi đặt hàng!",
                "",
                { timeOut: 10000 }
              );
            }
            this.cartService.refreshCart();
            this.router.navigateByUrl('/cart');
          } else {
            if (res.message) {
              console.log("Reoder message: ", res.message);
            }
            if (res.data.length) {
              this.toastService.error("Tất cả sản phẩm trong đơn hàng này không còn khả dụng, vui lòng kiểm tra lại hoặc liên hệ với nhân viên để được tư vấn!");
            } else {
              this.toastService.error("Đặt hàng lại lỗi, vui lòng thử lại sau");
            }
          }
        }
      },
      error: (err: any) => {
        this.toastService.error(err.error.message);
        this.cartService.refreshCart();
      }
    })

  }

  downloadQRCode() {
    this.qrCodeService.downloadQRCode(this.qrImgSrc, this.order.orderId);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
