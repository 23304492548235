import { PostModel } from '@/app/shared/interface/blog.interface';
import { Breadcrumb } from '@/app/shared/interface/breadcrumb';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID, SecurityContext, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrl: './blog-post.component.scss'
})
export class BlogPostComponent {
  @Input() postDetail: PostModel;

  breadcrumb: Breadcrumb;
  pageType: string = 'app-blog-post';

  constructor(
    public sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (isPlatformBrowser(this.platformId)) {
      if (changes['postDetail']) {
        this.createBreadcrumb();
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    }
  }

  ngOnInit() {
    this.postDetail.postContent = this.sanitizer.sanitize(SecurityContext.HTML, this.postDetail.postContent) || '';
  
    const uniqueSlugs = new Set();
    const filteredRelativedPosts = this.postDetail.relativedPosts.filter(post => {
      const slug = post.slug;
  
      if (!uniqueSlugs.has(slug)) {
        uniqueSlugs.add(slug);
        return true;
      }
      return false;
    });
  
    this.postDetail.relativedPosts = filteredRelativedPosts;

    this.createBreadcrumb();
  }

  createBreadcrumb() {
    this.breadcrumb = {
      title: this.postDetail.postTitle,
      items: [
        {
          label: 'Blog',
          url: '/blog',
          active: false
        },
        {
          label: this.postDetail.postTitle,
          active: true,
        }
      ]
    }
  }
}
