import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { BehaviorSubject } from "rxjs";
import { User } from "../interface/user.interface";

@Injectable({
    providedIn: "root",
})
export class WishlistService {

    private userData = new BehaviorSubject<User>(new User());
    public getUserData = this.userData.asObservable();
    setUserData(user: User) {
        this.userData.next(user);
    }

    constructor(private http: HttpClient) { }

    getWishlist() {
        return this.http.get<number[]>(`${environment.baseURL}user/user/wishlist`);
    }

    addWishlist(body: string) {
        return this.http.post<number[]>(`${environment.baseURL}user/user/wishlist`, body, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

}
